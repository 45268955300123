import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SliderSlick from "react-slick"
import SliderItem from "./SliderItem.js"
import { IsMobileDevice } from "../utils/index"

import "../scss/components/_Slider.scss"

function checkIfVideo(sliders){
    for (let i = 0; i < sliders.length; i++) {
        let item = sliders[i].node
        if (item.isVideo){
            return true
        }
    }
    return false;
}

const SliderHome = ({ sliders }) => {
    const [isMobile, setIsMobile] = useState(false)
    const [thereIsVideo, setThereIsVideo] = useState(false)

    useEffect(() => {
        setIsMobile(IsMobileDevice())
        setThereIsVideo(checkIfVideo(sliders))
    }, [])

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        speed: 1100,
    }
    const settings2 = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        pauseOnHover: false,
        speed: 1100,
    }

    return (
        thereIsVideo ?
            <SliderSlick {...settings2} style={{ backgroundColor: "#000" }}>
                {/* {sliders.length > 0
                    ?   sliders.map(slider => ( */}
                {sliders.length > 1 ? (
                    // <>
                    // <SliderItem slider={sliders} isMobile={isMobile} />
                    // <SliderItem slider={sliders} isMobile={isMobile} />
                    // </>
                    sliders.map(slider =>{
                        return(
                            <SliderItem slider={slider.node} isMobile={isMobile} />
                        )
                    })
                ) : (
                    // <div className="preRender" />
                    <SliderItem slider={sliders[0].node} isMobile={isMobile} />
                )}
            </SliderSlick> :
            <SliderSlick {...settings} style={{ backgroundColor: "#000" }}>
                {/* {sliders.length > 0
                    ?   sliders.map(slider => ( */}
                {sliders.length > 1 ? (
                    // <>
                    // <SliderItem slider={sliders} isMobile={isMobile} />
                    // <SliderItem slider={sliders} isMobile={isMobile} />
                    // </>
                    sliders.map(slider =>{
                        return(
                            <SliderItem slider={slider.node} isMobile={isMobile} />
                        )
                    })
                ) : (
                    // <div className="preRender" />
                    <SliderItem slider={sliders[0].node} isMobile={isMobile} />
                )}
            </SliderSlick>

    )
}

export default SliderHome